<template>
  <div class="search-table">
    <el-date-picker
        format="MM-yyyy"
        style="width: 200px"
        v-model="dateChoose"
        type="monthrange"
        align="right"
        unlink-panels
        @change="calendarSearch"
        range-separator="-"
        start-placeholder="Tháng bắt đầu"
        :clearable="false"
        end-placeholder="Tháng kết thúc">
    </el-date-picker>

    <!-- chọn trạng học cho nhân viên -->
    <el-select
        @change="handleChangeEmployeeStatus"
        style="width: 140px"
        class="input-common"
        v-model="dataSearch.employeeStatus"
        placeholder="Trạng thái"
        clearable
    >
      <el-option
          v-for="(item,index) in statusEmployeeListNew"
          :key="index"
          :value="item"
          :label="item"
      ></el-option>
    </el-select>
    <!--       chọn trạng thái -->
    <el-select
        style="width: 180px"
        v-model="dataSearch.transactionEntry"
        placeholder="Trạng thái kết chuyển"
        @change="handleChangeTransaction"
        clearable
    >
      <el-option
          v-for="(item,index) in  statusOrderList"
          :key="index"
          :value="item.value"
          :label="item.label"
      ></el-option>
    </el-select>
    <el-input
        @clear="handleClearEmployeeName"
        clearable
        style="width: 240px"
        placeholder="Nhập tên, SĐT"
        v-model="employeeName"
        @keyup.enter.native="handleChangeEmployeeName"
    >
      <el-button
          slot="append"
          icon="el-icon-search"
          @click="handleSearchResultByName"
      ></el-button>
    </el-input>
    <el-select
        @change="handleChangeSpendTurn"
        style="width: 100px"
        clearable
        v-model="dataSearch.spendTurn"
        placeholder="Đợt chi"
    >
      <el-option
          v-for="item in spendTurn"
          :key="item"
          :value="item"
          :label="`Đợt ${item}`"
      ></el-option>
    </el-select>

  </div>

</template>
<script>
import moment from 'moment'
import {statusEmployeeListNew} from "@/constant/listCommon";
import {mapActions, mapMutations, mapState} from "vuex";
import NewSalaryService from "@/services/EmployeeService/NewSalaryService";
export default {
  name: 'StatisticSearchEmployee',
  computed: {
    ...mapState('staticSalaryNew',[
        'loadingData',
        'responseFilterList',
        'updateStatus'
    ])
  },
  data() {
    return {
      statusEmployeeListNew,
      monthAndYear: moment().format('M-yyyy'),
      statusOrderList: [
        {label: 'Không chuyển', value: 'MONEY_WALLET'},
        {label: 'Sang tháng sau', value: 'MONEY_MONTH'}
      ],
      dateChoose: [],
      spendTurn: null,
      isUpdateSpendTurn: true,
      employeeName: null,
      dataSearch: {
        startDate: moment().format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
        transactionEntry: null,
        spendTurn: null,
        namePhone: null,
        employeeStatus:"Đang làm"
      },
    }
  },
  watch:{
    updateStatus: {
      handler() {
        this.isUpdateSpendTurn = false
        this.handleChangeTransaction();
      },
      deep: true,
    },
  },
  created() {
    this.dateChoose = [this.dataSearch.startDate, this.dataSearch.endDate]
    this.fetchDataTable();
  },
  methods: {
    ...mapMutations('staticSalaryNew',['setLoadingData','setResponseFilterList']),
    ...mapActions('staticSalaryNew',['getResponseFilterList']),
    fetchDataTable(){
      this.calendarSearch(); // Lấy danh sách trạng thái kết chuyển
    },

    // Thay đổi lịch
    calendarSearch(){
      this.dataSearch.startDate = moment(this.dateChoose[0]).format('YYYY-MM-DD');
      this.dataSearch.endDate = moment(this.dateChoose[1]).format('YYYY-MM-DD');
      this.handleChangeTransaction()
    },

    // Thay đổi trạng thái kết chuyển
    handleChangeTransaction(){
      if (this.dataSearch.transactionEntry === ""){
        this.dataSearch.transactionEntry = null
      }
      this.getSpendTurn()
    },

    // Lấy đợt chi
    getSpendTurn() {
      NewSalaryService.getStaticSpendTurn(this.dataSearch)
          .then((res) => {
            if (res.data === 0) {
              this.dataSearch.spendTurn = null;
              this.spendTurn = null;
              this.setLoadingData()
              this.getResponseFilterList(this.dataSearch)
              return;
            }
            this.spendTurn = res.data;
            this.dataSearch.spendTurn = this.spendTurn;
            this.setLoadingData()
            this.getResponseFilterList(this.dataSearch)
          });
    },

    // xử lý đổi trạng thái nhân viên
    handleChangeEmployeeStatus() {
      if (this.dataSearch.employeeStatus === ""){
        this.dataSearch.employeeStatus = null
      }
      this.setLoadingData()
      this.getResponseFilterList(this.dataSearch)
      this.getSpendTurn()
    },

    // bắt sự kiện tìm theo tên qua enter
    handleChangeEmployeeName() {
      this.setLoadingData()
      if (this.employeeName === ""){
        this.dataSearch.namePhone = null
      }else {
        this.dataSearch.namePhone = this.employeeName;
      }
      this.getResponseFilterList(this.dataSearch)
    },

    // Trả ra kết quả tìm kiếm theo tên khi bấm vào icon tìm kiếm
    handleSearchResultByName() {
      this.setLoadingData()
      if (this.employeeName === ""){
        this.dataSearch.namePhone = null
      }else {
        this.dataSearch.namePhone = this.employeeName;
      }
      console.log(this.dataSearch.namePhone)
      this.getResponseFilterList(this.dataSearch)
    },
    // tìm kiếm khi xóa tên nhân viên
    handleClearEmployeeName() {
      this.setLoadingData()
      if (this.employeeName === ""){
        this.dataSearch.namePhone = null
      }else {
        this.dataSearch.namePhone = this.employeeName;
      }
      this.getResponseFilterList(this.dataSearch)
    },
    // Thay đổi đợt chi
    handleChangeSpendTurn() {
      this.setLoadingData()
      this.getResponseFilterList(this.dataSearch)
    },
  }
}
</script>

<style lang="scss" scoped>
.search-table {
  margin-bottom: 20px;
  z-index: 1;
  display: flex;
  width: 100%;
  gap: 0.3rem;
  flex-wrap: wrap;
}
</style>
