<template>
  <div class="dialog-container">
    <el-dialog
        width="700px"
        title="Xuất file thống kê"
        :visible.sync="showExportStatisticsFile"
        :before-close="handleCloseExportStatisticsFile"
        :close-on-click-modal="false"
    >
      <div>
        <el-radio-group v-model="selectExport">
          <el-radio :label="1" style="margin-top: 20px">Xuất danh sách các nhân viên chưa hoàn thành phiếu lương</el-radio>
          <el-radio :label="2" style="margin-top: 30px">Xuất danh sách các nhân viên đã hoàn thành phiếu lương</el-radio>
          <el-radio :label="3" style="margin-top: 30px">Xuất danh sách các nhân viên chưa có phiếu lương</el-radio>
        </el-radio-group>
      </div>
      <div class="buttons">
        <el-button
            :loading="loadingExportFile"
            size="medium"
            type="success"
            @click="handleExportFile()"
        >
          OK
        </el-button>
        <el-button size="medium" type="danger" @click="handleCloseExportStatisticsFile">
          <i class="el-icon-circle-close"/> Đóng
        </el-button>
      </div>
    </el-dialog>
    <ExportEmployeeNotCompleted
        :showExportStudentNotCompleted="showExportStudentNotCompleted"
        @close="closeExportEmployeeNotCompleted"
        ref="ExportStudentNotCompleted"
    />
    <ExportEmployeeComplete
        :showExportStudentComplete="showExportStudentComplete"
        @close="closeExportEmployeeComplete"
        ref="ExportEmployeeComplete"
    />
    <ExportEmployeeNotOrder
        :showExportStudentNotOrder="showExportStudentNotOrder"
        @close="closeExportStudentNotOrder"
        ref="ExportStudentComplete"
    />
  </div>
</template>

<script>
import ExportEmployeeNotCompleted
  from "@/components/FinanceVer2/employeeStatistics/TableDialog/ExportEmployeeNotCompletedDialog.vue";
import ExportEmployeeComplete
  from "@/components/FinanceVer2/employeeStatistics/TableDialog/ExportEmployeeCompleteDialog.vue";
import ExportEmployeeNotOrder
  from "@/components/FinanceVer2/employeeStatistics/TableDialog/ExportEmployeeNotOrderDialog.vue";

export default {
  name: "ExportStatisticsEmployeeFile",
  components: {
    ExportEmployeeNotOrder,
    ExportEmployeeComplete,
    ExportEmployeeNotCompleted},
  data() {
    return {
      selectExport: 1,
      loadingExportFile: false,
      showExportStudentNotCompleted: false,
      showExportStudentComplete: false,
      showExportStudentNotOrder: false,
      listNameStudentNotInClass: []
    }
  },
  props: {
    showExportStatisticsFile: {
      type: Boolean,
      default: () => false,
    },
  },
  methods: {
    handleCloseExportStatisticsFile() {
      this.selectExport = 1
      this.$emit("close", false);
    },
    // Xuất file
    async handleExportFile() {
      if (this.selectExport === 1) {
        this.showExportStudentNotCompleted = true
      } else if (this.selectExport === 2) {
        this.showExportStudentComplete = true
      } else if (this.selectExport === 3){
        this.showExportStudentNotOrder = true
      }
    },

    closeExportEmployeeNotCompleted() {
      this.showExportStudentNotCompleted = false
    },
    closeExportEmployeeComplete() {
      this.showExportStudentComplete = false
    },
    closeExportStudentNotOrder() {
      this.showExportStudentNotOrder = false
    }
  },
}
</script>

<style lang="scss" scoped>
.dialog-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.buttons {
  display: flex;
  justify-content: right;
  text-align: right;
  margin-top: 30px;
}
</style>