<template>
  <div>
    <section style="height: 2.5rem">
      <StatisticsSearchEmployee/>
    </section>
    <section style="margin-top: 20px">
      <StatisticsTableEmployee/>
    </section>

  </div>
</template>

<script>
import StatisticsTableEmployee from "@/components/FinanceVer2/employeeStatistics/StatisticsTableEmployee";
import StatisticsSearchEmployee from "@/components/FinanceVer2/employeeStatistics/StatisticsSearchEmployee";

export default {
  name: "NewEmployeeSalaryStatistics",
  components: {
    StatisticsTableEmployee,
    StatisticsSearchEmployee
  }
}
</script>

<style scoped>

</style>