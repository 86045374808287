var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "section",
      { staticStyle: { height: "2.5rem" } },
      [_c("StatisticsSearchEmployee")],
      1
    ),
    _c(
      "section",
      { staticStyle: { "margin-top": "20px" } },
      [_c("StatisticsTableEmployee")],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }